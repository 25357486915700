.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 50%;

  gap: size(30px);

  @include vp-1919 {
    gap: 30px;
  }

  @include vp-1619 {
    grid-template-rows: repeat(2, auto);
  }

  @include vp-1187 {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    grid-auto-rows: 1fr;
  }

  @include vp-1023 {
    gap: 40px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
    gap: 10px 0;
  }

  &--50-50 {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    column-gap: size(20px);

    @include vp-1919 {
      column-gap: 20px;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  &--rows {
    grid-auto-rows: auto;
    grid-template-rows: auto;
  }

  &--category {
    gap: size(60px) size(30px);
    grid-auto-rows: auto;

    @include vp-1919 {
      gap: 60px 30px;
    }

    @include vp-1187 {
      gap: 30px 20px;
    }

    @include vp-767 {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 10px;
    }
  }
}

.grid-container__col-1 {
  display: flex;
  flex-wrap: wrap;
}
