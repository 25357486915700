.btn-block {
  display: flex;

  &__print {
    border: size(1px) solid $color-transparent;
    background-color: $color-default-white;
    transition: border-color $trans-default;
    cursor: pointer;

    @include vp-1919 {
      border-width: 1px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-bottom-color: $color-default-black;
      }

      &:active {
        border-bottom-color: $color-transparent;
      }
    }

    img {
      width: size(18px);
      height: size(15px);
      object-fit: contain;

      @include vp-1919 {
        width: 18px;
        height: 15px;
      }
    }
  }
}
