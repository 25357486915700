.main-nav {
  position: relative;
  pointer-events: all;

  @include vp-1187 {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    padding: 12px 16px;

    pointer-events: none;

    .is-active &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      width: 100%;
      height: 100vh;
      /* stylelint-disable-next-line */
      height: calc(100 * var(--vh, 1vh));

      background-color: $color-default-black;
      opacity: 0.6;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: size(18px) 0;

    @include vp-1919 {
      padding: 18px 0;
    }

    @include vp-1187 {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;

      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      min-height: 752px;
      padding: 100px 20px 40px;

      background-color: $color-default-white;

      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: opacity $trans-default, background-color $trans-default;
      animation: disableBreakpointAnimation 0.2s ease;
    }

    @include vp-1023 {
      min-height: 978px;
    }

    @include vp-767 {
      align-items: center;
      min-height: 809px;
    }

    &.is-active {
      @include vp-1187 {
        background-color: $color-nero;
        visibility: visible;
        animation: disableBreakpointAnimation 0.2s ease;
        opacity: 1;

        pointer-events: all;
      }
    }

    &.is-active .dropdown {
      @include vp-1187 {
        pointer-events: auto;
      }
    }
  }

  &__list-wrapper {
    width: 70%;
    max-width: size(900px);

    @include vp-1919 {
      max-width: 900px;
    }

    @include vp-1187 {
      max-width: 100%;
      width: 100%;

      padding-top: 72px;
    }

    @include vp-1023 {
      padding-top: 64px;
    }

    @include vp-767 {
      padding-top: 8px;
    }
  }

  &__list-wrapper--inform {
    width: initial;
    display: flex;
    align-items: center;

    @include vp-1187 {
      .main-nav__list {
        margin-bottom: 0;
      }

      .main-nav__link {
        font-size: 24px;
        text-transform: none;
      }

      .main-nav__item {
        margin-right: 34px;
        padding-bottom: 0;
      }
    }

    @include vp-1023 {
      position: absolute;
      right: 18px;
      top: 160px;
      bottom: 62px;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: flex-end;

      .main-nav__list {
        flex-wrap: nowrap;
      }

      .main-nav__item {
        margin-right: 50px;
      }
    }

    @include vp-767 {
      position: static;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .main-nav__list {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }

      .main-nav__link {
        font-size: 14px;
      }

      .main-nav__item {
        margin-right: 42px;
      }
    }
  }
}

.main-nav__list {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;

  @include vp-1187 {
    display: block;
    column-count: 2;
    //column-width: 48%;

    margin-bottom: 42px;
  }

  @include vp-1023 {
    display: flex;
    flex-wrap: wrap;
  }

  @include vp-767 {
    flex-direction: column;
    align-items: center;

    margin-bottom: 24px;
  }
}

.main-nav__item {
  position: relative;

  padding-right: size(42px);

  @include vp-1919 {
    padding-right: 42px;
  }

  @include vp-1279 {
    padding-right: 32px;
  }

  @include vp-1187 {
    padding-right: 0;
    padding-bottom: 38px;
  }

  @include vp-1023 {
    margin-right: 40px;
  }

  @include vp-767 {
    margin-right: 0;
    padding-bottom: 10px;
  }

  &:first-child {
    @include vp-1023 {
      width: 100%;
    }

    @include vp-767 {
      width: auto;
    }
  }

  &:nth-child(2) {
    @include vp-1023 {
      width: 100%;
    }

    @include vp-767 {
      width: auto;
      text-align: center;
    }
  }

  &:last-child {
    @include vp-1187 {
      padding-bottom: 0;
    }

    @include vp-1023 {
      margin-right: 0;
    }
  }

  &--no-desktop {
    display: none;

    @include vp-1187 {
      display: block;
    }
  }
}

.main-nav__item.navbar-nav-more {
  @include vp-1187 {
    display: none;
  }
}

.main-nav__link {
  position: relative;

  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: size(18px);
  line-height: 100%;
  white-space: nowrap;
  color: $color-default-black;
  border-bottom: size(1px) solid transparent;

  outline: none;

  transition: border-color $trans-default;
  //user-select: none;
  cursor: pointer;

  @include vp-1919 {
    font-size: 18px;
    border-bottom: 1px solid transparent;
  }

  @include vp-1187 {
    display: inline-flex;
    justify-content: flex-start;

    font-weight: 400;
    font-size: 48px;
    line-height: 100%;
    text-transform: uppercase;

    color: $color-default-white;
  }

  @include vp-767 {
    font-size: 18px;
  }

  svg {
    width: size(9px);
    height: size(7px);
    margin: size(1px) 0 size(1px) size(5px);

    fill: transparent;

    transition: transform $trans-default;
    pointer-events: none;

    @include vp-1919 {
      width: 9px;
      height: 7px;
      margin: 1px 0 1px 5px;
    }

    @include vp-1187 {
      display: none;
    }
  }

  &--icon-right {
    @include vp-1187 {
      font-size: 16px;
      line-height: 100%;
    }
  }

  &--icon-right svg {
    transform: rotate(-90deg);
  }

  span {
    display: none;

    @include vp-1187 {
      display: block;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-default-black;

      @include vp-1187 {
        border-color: $color-default-white;
        opacity: 0.6;
      }
    }

    &:active {
      border-color: $color-default-black;

      @include vp-1187 {
        border-color: $color-default-white;
        opacity: 0.6;
      }
    }
  }

  &.is-active {
    border-color: $color-default-black;

    svg {
      transform: rotate(180deg);
    }

    @include vp-1187 {
      color: $color-default-white;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: var(--color-text-hover);
        }

        &:active {
          color: var(--color-text-active);
        }
      }
    }
  }
}

.main-nav__link--dots {
  display: none;

  @include vp-1187 {
    position: relative;
    display: block;
    width: 20px;
    height: 16px;

    border-radius: 30px;
    transition: opacity $trans-default;
  }

  &::before,
  &::after {
    position: absolute;
    top: 40%;

    width: size(4px);
    height: size(4px);

    background-color: $color-default-black;

    border-radius: size(30px);
    transition: opacity $trans-default;
    content: "";

    @include vp-1919 {
      width: 4px;
      height: 4px;

      border-radius: 30px;
    }
  }

  &::before {
    left: 0;

    box-shadow: size(8px) 0 0 0 $color-default-black;

    @include vp-1919 {
      box-shadow: 8px 0 0 0 $color-default-black;
    }
  }

  &::after {
    right: 0;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: transparent;
    }

    &:active {
      border-color: transparent;
    }

    &:hover::before,
    &:focus::before,
    &:hover::after,
    &:focus::after {
      opacity: 0.6;
    }

    &:active::before,
    &:active::after {
      opacity: 0.6;
    }

    &:hover::before,
    &:focus::before {
      opacity: 0.6;
    }

    &:active::before {
      opacity: 0.6;
    }
  }

  &.is-active {
    border-color: transparent;
  }
}

.main-nav__link.is-active + .dropdown {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  z-index: 1;
  transform: translate3d(size(-110px), size(16px), 0) rotateX(0deg);
  animation: disableBreakpointAnimation 0.2s ease;

  @include vp-1919 {
    transform: translate3d(-110px, 16px, 0) rotateX(0deg);
  }

  @include vp-1187 {
    transform: none;
  }

  &--center {
    transform: translate3d(-43%, size(16px), 0) rotateX(0deg);

    @include vp-1919 {
      transform: translate3d(-43%, 16px, 0) rotateX(0deg);
    }
  }
}

.is-active .main-nav {
  @include vp-1187 {
    pointer-events: all;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
