.main-news {
  grid-column: span 3;
  //grid-row: 1;

  @include vp-1619 {
    grid-column: span 12;
    grid-row: 2;
  }

  @include vp-1187 {
    grid-column: span 2;
    grid-row: 3;

    padding-top: 30px;
  }

  @include vp-1023 {
    padding-top: 0;
  }

  &__block-title {
    margin: 0 0 size(8px);

    font-weight: 400;
    font-size: size(18px);
    line-height: size(22px);

    @include vp-1919 {
      margin: 0 0 8px;

      font-size: 18px;
      line-height: 22px;
    }

    @include vp-1619 {
      padding-bottom: 26px;
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      border-bottom: 2px solid $color-westar;
    }

    @include vp-1023 {
      position: relative;
      display: flex;
      padding-bottom: 0;
      border-bottom: none;

      &::after {
        content: "";
        flex: 1;
        margin: auto 44px;
        margin-right: 0;
        height: 2px;

        background-color: $color-westar;
      }
    }

    @include vp-767 {
      margin-bottom: 20px;

      font-size: 14px;
      line-height: 17px;

      &::before {
        width: 55%;
      }
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    padding: size(4px) 0 size(17px);
    border-top: size(1px) solid $color-westar;

    @include vp-1919 {
      padding: 4px 0 17px;
      border-width: 1px;
    }

    @include vp-1619 {
      padding: 0 0 0 39px;
      margin-bottom: 27px;
      border-top: none;
      border-left: 1px solid $color-westar;
    }

    @include vp-767 {
      padding: 0 0 0 25px;
      margin-bottom: 27px;
    }
  }

  &__title {
    max-width: 95%;
    margin: size(6px) 0 0;
    font-weight: 700;
    font-size: size(24px);
    line-height: size(31px);

    @include vp-1919 {
      margin: 6px 0 0;
      font-size: 24px;
      line-height: 31px;
    }

    @include vp-1619 {
      max-width: 100%;
    }

    @include vp-767 {
      max-width: 86%;
      margin: 0;

      font-size: 14px;
      line-height: 18px;
    }

    a {
      border-bottom: 1px solid transparent;
      transition: border $trans-default;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        a {
          border-color: $color-default-black;
        }
      }

      &:active {
        a {
          border-color: $color-default-black;
        }
      }
    }
  }

  //&__meta-icon {
  //  display: none;
  //
  //  @include vp-1619 {
  //    display: flex;
  //  }
  //}

  &__meta-date {
    font-size: size(14px);
    line-height: size(15px);

    @include vp-1919 {
      font-size: 14px;
      line-height: 15px;
    }

    @include vp-1187 {
      font-size: 18px;
      line-height: 22px;
    }

    @include vp-767 {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--full-size {
    grid-column: span 12;
    grid-row: 3;

    padding-top: size(32px);

    @include vp-1619 {
      grid-column: span 12;
      grid-row: 3;
    }

    @include vp-1187 {
      grid-column: span 2;
      grid-row: 3;

      padding-top: 0;
    }

    .main-news__block-title {
      font-weight: 700;
      font-size: size(24px);
      line-height: size(29px);

      position: relative;
      display: flex;
      padding-bottom: 0;
      border-bottom: none;

      &::after {
        content: "";
        flex: 1;
        margin: auto 44px;
        margin-right: 0;
        height: 2px;

        background-color: $color-westar;
      }

      @include vp-1919 {
        margin: 6px 0 0;
        font-size: 24px;
        line-height: 29px;
      }

      @include vp-1187 {
        margin-top: 0;
      }

      @include vp-767 {
        margin-bottom: 20px;

        font-size: 14px;
        line-height: 17px;

        &::before {
          width: 55%;
        }
      }
    }

    .main-news__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      padding: size(42px) 0 size(40px);

      @include vp-1919 {
        padding: 42px 0 40px;
      }

      @include vp-1619 {
        flex-direction: column;
        padding: 68px 0 2px;
      }

      @include vp-1023 {
        flex-direction: column;
        padding: 40px 0 2px;
      }

      @include vp-767 {
        padding: 0;
      }
    }

    .main-news__item {
      width: 23%;
      padding: size(4px) size(32px) 0;
      border-right: size(1px) solid $color-westar;
      border-top: none;

      display: flex;
      flex-direction: column;

      @include vp-1919 {
        padding: 4px 32px 0;
      }

      @include vp-1619 {
        width: 100%;
        border-right: none;
      }

      @include vp-1619 {
        padding: 0 0 0 39px;
        margin-bottom: 27px;
        border-top: none;
        border-left: 1px solid $color-westar;
      }

      @include vp-767 {
        padding: 0 0 0 25px;
        margin-bottom: 27px;
      }
    }

    .main-news__title {
      max-width: none;
      margin: 0 0 size(14px);
      order: -1;

      @include vp-1919 {
        margin: 0 0 14px;
      }

      @include vp-1187 {
        margin: 0 0 6px;
      }
    }
  }
}
