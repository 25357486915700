.btn-toggle {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 7;

  height: 50px;
  border: none;
  background-color: $color-default-white;
  outline: none;

  transition: background-color $trans-default, translate $trans-default, rotate $trans-default;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  @include hover-focus {
    opacity: .6;
  }

  span {
    width: 64px;
    height: 4px;

    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    pointer-events: none;

    opacity: 1;

    background-color: $color-default-black;
    transition: opacity $trans-default;

    @include vp-767 {
      width: 42px;
      height: 3px;
    }

    &::before,
    &::after {
      position: absolute;
      right: 0;

      width: 64px;
      height: 4px;

      background-color: $color-default-black;
      transition: opacity $trans-default, translate $trans-default, rotate $trans-default;
      content: "";

      @include vp-767 {
        width: 42px;
        height: 3px;
      }
    }

    &::before {
      transform: translateY(-12px);

      @include vp-767 {
        transform: translateY(-8px);
      }
    }

    &::after {
      transform: translateY(12px);

      @include vp-767 {
        transform: translateY(8px);
      }
    }
  }

  &--no-mobile {
    @include vp-1187 {
      display: none;
    }
  }

  &--no-desktop {
    display: none;

    @include vp-1187 {
      display: inline-flex;
    }
  }

  &--no-index {
    z-index: 0;
  }

  &.is-active {
    position: relative;

    background-color: transparent;
    z-index: 10;

    span::before,
    span::after {
      width: 54px;

      @include vp-767 {
        width: 38px;
      }
    }

    span::before {
      background-color: $color-default-white;
      transform: translateY(0) rotate(-45deg);
    }

    span::after {
      background-color: $color-default-white;
      transform: translateY(0) rotate(45deg);
    }

    @include hover-focus {
      opacity: .6;
    }

    @include vp-1187 {
      span {
        background-color: $color-nero;
      }
    }
  }
}
