.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;

  display: flex;
  flex-direction: column;

  &__top {
    padding: size(26px) 0 size(24px);
    background-color: $color-default-white;

    @include vp-1919 {
      padding: 26px 0 24px;
    }

    @include vp-1279 {
      padding: 25px 0;
    }

    @include vp-1187 {
      transition: background-color $trans-200;
    }

    @include vp-767 {
      padding: 8px 0 14px;
    }
  }

  &__top .container {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .logo {
    @include vp-1187 {
      margin-left: 5px;
      z-index: 10;
    }
  }

  & .logo.is-absolute {
    position: relative;
    z-index: 10;
  }

  &__right-block {
    display: flex;
  }

  &--no-nav {
    .header__wrapper {
      padding: 18px 0;
    }

    .header__search {
      display: none;

      @include vp-1023 {
        display: flex;
      }
    }

    .main-nav {
      display: none;

      @include vp-1023 {
        display: block;
      }
    }
  }

  &.is-active {
    @include vp-1187 {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;

        height: 2px;

        background-color: $color-westar;
      }

      .header__top {
        background-color: $color-nero;
        z-index: 1;
      }
    }
  }
}

.header__bottom {
  background: $color-white-smoke;
}
