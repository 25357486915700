// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2");
// }

@font-face {
  font-family: "Proxima Nova";
  font-weight: 300;
  font-style: normal;

  font-display: swap;
  src: url("../fonts/proximanova_light-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_light-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  font-style: normal;

  font-display: swap;
  src: url("../fonts/proximanova_regular-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-style: normal;

  font-display: swap;
  src: url("../fonts/proximanova_bold-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-style: italic;

  font-display: swap;
  src: url("../fonts/proximanova_boldit-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_boldit-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 800;
  font-style: normal;

  font-display: swap;
  src: url("../fonts/proximanova_extrabold-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_extrabold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 900;
  font-style: normal;

  font-display: swap;
  src: url("../fonts/proximanova_black-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_black-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 900;
  font-style: italic;

  font-display: swap;
  src: url("../fonts/proximanova_blackit-webfont.woff2") format("woff2"),
    url("../fonts/proximanova_blackit-webfont.woff") format("woff");
}
