.news-card {
  position: relative;
  display: flex;
  overflow: hidden;

  &__image-wrapper {
    background-color: $color-white-smoke;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content-wrapper {
    padding: size(20px);

    @include vp-1919 {
      padding: 20px;
    }

    @include vp-1023 {
      padding: 20px;
    }

    @include vp-767 {
      padding: 20px 10px;
    }

    a {
      display: inline-block;
      font-weight: 700;
    }
  }

  &__category {
    padding: 0;

    font-size: size(18px);
    line-height: size(22px);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $color-default-white;
    opacity: 0.7;

    @include vp-1919 {
      font-size: 18px;
      line-height: 22px;
    }

    @include vp-767 {
      font-size: 8px;
      line-height: 10px;
    }
  }

  &__title {
    margin: 0;
    margin-top: size(18px);

    font-size: size(24px);
    line-height: 100%;
    color: $color-default-white;

    display: -webkit-box;
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @include vp-1919 {
      margin-top: 18px;

      font-size: 24px;
    }

    @include vp-1023 {
      margin-top: 15px;
    }

    @include vp-767 {
      margin-top: 5px;
      font-size: 22px;
      line-height: 24px;
    }

    //a {
    //  transition: color $trans-default, text-decoration-color $trans-default;
    //  display: -webkit-box;
    //  overflow: hidden;
    //
    //  text-overflow: ellipsis;
    //  -webkit-line-clamp: 3;
    //  -webkit-box-orient: vertical;
    //}
  }

  &__meta-date {
    font-size: size(18px);
    line-height: size(22px);

    @include vp-1919 {
      font-size: 18px;
      line-height: 22px;
    }

    @include vp-767 {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__tag {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include vp-767 {
      flex-direction: row;
    }
  }

  &__tag-text {
    width: fit-content;
    font-width: 700;
    font-size: size(18px);
    line-height: size(22px);
    letter-spacing: 0.7px;
    text-transform: uppercase;

    padding: size(10px) size(22px);
    margin-bottom: size(10px);

    background-color: $color-default-black;
    color: $color-default-white;

    @include  vp-1919 {
      font-size: 18px;
      line-height: 22px;
      padding: 10px 22px;
      margin-bottom: 10px;
    }

    @include  vp-767 {
      font-size: 12px;
      line-height: 15px;
      padding: 9px 19px;
      margin-bottom: 0;
      margin-right: 6px;
    }
  }

  &--img-card {
    min-height: size(395px);

    transition: transform $trans-default;

    @include vp-1919 {
      min-height: 323px;
    }

    @include vp-1619 {
      min-height: 317px;
    }

    @include vp-1023 {
      min-height: 300px;
    }

    @include vp-767 {
      min-height: 250px;
    }

    @include vp-374 {
      min-height: 195px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 74%;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      z-index: 1;

      @include vp-767 {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%);
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        img {
          transform: scale(1.15);
        }
      }

      &:active {
        img {
          transform: scale(1.15);
        }
      }
    }

    .news-card__image-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;

      @include vp-1619 {
        position: relative;
        padding-bottom: 89%;
        height: 0;
      }

      @include vp-1023 {
        padding-bottom: 95%;
      }

      @include vp-767 {
        padding-bottom: 83.5%;
      }

      img {
        object-fit: cover;
        transition: transform $trans-default;

        @include vp-1619 {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .news-card__content-wrapper {
      position: absolute;
      bottom: 0;
      max-height: size(300px);
      z-index: 5;

      padding-bottom: size(34px);
      padding-left: size(30px);

      @include vp-1919 {
        padding-bottom: 34px;
        padding-left: 30px;
      }

      @include vp-1023 {
        padding-bottom: 30px;
        padding-left: 20px;
      }

      @include vp-767 {
        padding-bottom: 18px;
        padding-left: 10px;
      }
    }
  }

  &--big {
    grid-column: span 6;
    grid-row: span 2;

    @include vp-1619 {
      grid-column: span 8;
      grid-row: span 1;
    }

    @include vp-1187 {
      grid-column: span 2;
      grid-row: 1;
    }

    @include vp-767 {
      grid-column: 1;
      grid-row: 1;
    }

    .news-card::before {
      height: 63%;
    }

    .news-card__image-wrapper {
      @include vp-1187 {
        padding-bottom: 83.5%;
      }
    }

    .news-card__content-wrapper {
      padding: size(20px) size(40px) size(64px) size(60px);

      @include vp-1919 {
        padding: 20px 40px 64px 60px;
      }

      @include vp-1023 {
        padding: 20px 20px 44px 30px;
      }

      @include vp-767 {
        padding: 20px 10px 18px;
      }
    }


    .news-card__title {
      margin-top: size(22px);

      font-size: size(48px);
      line-height: size(58px);

      @include vp-1919 {
        margin-top: 22px;

        font-size: 48px;
        line-height: 58px;
      }

      @include vp-1023 {
        margin-top: 16px;

        font-size: 36px;
        line-height: 44px;
      }

      @include vp-767 {
        margin-top: 5px;

        font-size: 22px;
        line-height: 27px;
      }
    }
  }

  &--img-text-card {
    flex-direction: column;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .news-card__title {
          text-decoration-color: $color-default-black;
        }
      }

      &:active {
        .news-card__title {
          text-decoration-color: $color-default-black;
        }
      }
    }

    .news-card__image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 76%;
      height: 0;
      overflow: hidden;

      @include vp-1187 {
        padding-bottom: 75%;
      }

      @include vp-1023 {
        padding-bottom: 79%;
      }

      @include vp-767 {
        padding-bottom: 86.5%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    .news-card__content-wrapper {
      padding: size(40px) size(10px) size(4px) 0;

      @include vp-1919 {
        padding: 40px 10px 4px 0;
      }

      @include vp-1187 {
        padding: 36px 0 4px;
      }

      @include vp-767 {
        padding: 12px 0 4px;
      }

      a {
        color: $color-default-black;
      }
    }

    .news-card__meta {
      margin-bottom: size(18px);

      @include vp-1919 {
        margin-bottom: 18px;
      }

      @include vp-1023 {
        margin-bottom: 16px;
      }

      @include vp-767 {
        margin-bottom: 4px;
      }
    }

    .news-card__title {
      line-height: 112%;
      margin-top: 0;
      color: $color-default-black;
      letter-spacing: -0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: color $trans-default, text-decoration-color $trans-default;

      @include vp-1919 {
        margin-top: 0;
      }

      @include vp-1023 {
        margin-top: 0;
        line-height: 114%;
      }

      @include vp-767 {
        font-size: 14px;
        line-height: 130%;
        margin-top: 0;
      }
    }
  }

  &--img-text-card-big {
    flex-direction: column;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .news-card__title {
          text-decoration-color: $color-default-black;
        }
      }

      &:active {
        .news-card__title {
          text-decoration-color: $color-default-black;
        }
      }
    }

    .news-card__image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 72.5%;
      height: 0;
      overflow: hidden;

      @include vp-1187 {
        padding-bottom: 67.5%;
      }

      @include vp-767 {
        padding-bottom: 66.5%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    .news-card__content-wrapper {
      padding: size(30px) 0 size(4px);

      @include vp-1919 {
        padding: 30px 0 4px;
      }

      @include vp-1023 {
        padding: 18px 0 4px;
      }

      @include vp-767 {
        padding: 6px 0 4px;
      }
    }

    .news-card__title {
      max-width: 90%;
      margin-top: 10px;

      font-weight: 700;
      font-size: size(48px);
      line-height: size(62px);

      color: $color-default-black;
      letter-spacing: -0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: color $trans-default, text-decoration-color $trans-default;


      @include vp-1919 {
        font-size: 48px;
        line-height: 62px;
      }

      @include vp-1187 {
        max-width: 83%;
        margin-top: 10px;
      }

      @include vp-1023 {
        max-width: 100%;
        font-size: 36px;
        line-height: 47px;
      }

      @include vp-767 {
        font-size: 22px;
        line-height: 29px;

        margin-top: 10px;
      }
    }

    .meta {
      width: 100%;
    }

    .news-card__meta-date {
      position: relative;
      display: flex;
      width: 100%;

      &::after {
        content: "";
        flex: 1;
        margin: auto size(64px);
        margin-right: 0;
        height: size(2px);

        background-color: $color-westar;

        @include vp-1919 {
          margin: auto 64px;
          margin-right: 0;
          height: 2px;
        }

        @include vp-767 {
          margin-left: 64px;
        }
      }
    }
  }

  &--text-right {
    @include vp-767 {
      flex-direction: column;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .news-card__title {
          text-decoration-color: $color-default-black;
        }
      }

      &:active {
        .news-card__title {
          text-decoration-color: $color-default-black;
        }
      }
    }

    .news-card__image-wrapper {
      position: relative;
      width: 100%;
      max-width: size(442px);
      padding-bottom: 31%;
      height: 0;
      overflow: hidden;

      @include vp-1919 {
        max-width: 442px;
      }

      @include vp-1187 {
        padding-bottom: 28.5%;
      }

      @include vp-1023 {
        max-width: 48.5%;
        padding-bottom: 30.5%;
      }

      @include vp-767 {
        max-width: 100%;
        padding-bottom: 66.5%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    .news-card__content-wrapper {
      padding: 0 size(28px) size(20px);

      @include vp-1919 {
        padding: 0 28px 20px;
      }

      @include vp-1023 {
        padding: 0 2px 6px 22px;
      }

      @include vp-767 {
        padding: 4px 6px 4px 0;
      }
    }

    .news-card__title {
      margin-top: size(14px);
      margin-bottom: size(20px);

      line-height: 116%;
      color: $color-default-black;
      letter-spacing: -0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: color $trans-default, text-decoration-color $trans-default;


      @include vp-1919 {
        margin-top: 14px;
        margin-bottom: 20px;
      }

      @include vp-767 {
        font-size: 14px;
        line-height: 18px;

        margin-top: 8px;
        margin-bottom: 10px;
      }
    }
  }

  &--mobile-none {
    @include vp-767 {
      display: none;
    }
  }

  &--img-text-article {
    flex-direction: column;

    .news-card__image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 67%;
      height: 0;
      overflow: hidden;

      @include vp-1187 {
        padding-bottom: 75%;
      }

      @include vp-1023 {
        padding-bottom: 79%;
      }

      @include vp-767 {
        padding-bottom: 86.5%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }

    .news-card__content-wrapper {
      padding: size(40px) size(10px) size(4px) 0;

      @include vp-1919 {
        padding: 40px 10px 4px 0;
      }

      @include vp-1187 {
        padding: 24px 0 4px;
      }

      @include vp-767 {
        padding: 12px 0 4px;
      }

      a {
        color: $color-default-black;
      }
    }

    .news-card__meta {
      margin-bottom: size(18px);

      @include vp-1919 {
        margin-bottom: 18px;
      }

      @include vp-1023 {
        margin-bottom: 16px;
      }

      @include vp-767 {
        margin-bottom: 4px;
      }
    }

    .news-card__title {
      line-height: 112%;
      margin-top: 0;

      @include vp-1919 {
        margin-top: 0;
      }

      @include vp-1023 {
        //margin-top: 16px;
        margin-top: 0;
        line-height: 114%;
      }

      @include vp-767 {
        font-size: 14px;
        line-height: 130%;
        margin-top: 0;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          a {
            text-decoration-color: $color-default-black;
          }
        }

        &:active {
          a {
            text-decoration-color: $color-default-black;
          }
        }
      }
    }

    .news-card__title a {
      letter-spacing: -0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;
    }
  }

  &--category-big {
    grid-column: span 6;

    @include vp-1187 {
      grid-column: span 2;
    }

    .news-card::before {
      height: 63%;
    }

    .news-card__image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 60.5%;
      height: 0;

      @include vp-1023 {
        padding-bottom: 62.5%;
      }

      @include vp-767 {
        padding-bottom: 83%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: transform $trans-default;
      }
    }

    .news-card__title {
      max-width: 90%;
      margin-top: size(10px);

      font-weight: 700;
      font-size: size(48px);
      line-height: size(62px);

      @include vp-1919 {
        margin-top: 10px;
        font-size: 48px;
        line-height: 62px;
      }

      @include vp-1187 {
        max-width: 83%;
        margin-top: 10px;
      }

      @include vp-1023 {
        max-width: 100%;
        font-size: 36px;
        line-height: 47px;
      }

      @include vp-767 {
        font-size: 22px;
        line-height: 24px;

        margin-top: 10px;
      }

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          a {
            text-decoration-color: $color-default-black;
          }
        }

        &:active {
          a {
            text-decoration-color: $color-default-black;
          }
        }
      }
    }

    .news-card__title a {
      letter-spacing: -0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;
    }

    .news-card__lead {
      max-width: 70%;
      margin: size(20px) 0 0;
      font-weight: 400;
      font-size: size(24px);
      line-height: 102%;
      color: $color-default-white;

      @include  vp-1919 {
        margin: 20px 0 0;
        font-size: 24px;
      }

      @include  vp-1187 {
        margin: 20px 0 0;

        line-height: 112%;
      }

      @include  vp-1023 {
        margin: 10px 0 0;
        font-size: 18px;
        line-height: 22px;
      }

      @include  vp-767 {
        max-width: 100%;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .news-card__content-wrapper {
      padding: size(20px) size(40px) size(64px) size(60px);

      @include vp-1919 {
        padding: 20px 40px 64px 60px;
      }

      @include vp-1187 {
        padding: 20px 40px 74px 64px;
      }

      @include vp-1023 {
        padding: 20px 20px 40px;
      }

      @include vp-767 {
        padding: 20px 10px 18px;
      }
    }
  }

  &--category {
    grid-column: span 4;

    @include vp-1187 {
      grid-column: span 1;
      padding-top: 28px;
    }

    @include vp-767 {
      padding-top: 0;
    }

    .news-card__link {
      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          .news-card__title {
            text-decoration-color: $color-default-black;
          }
        }

        &:active {
          .news-card__title {
            text-decoration-color: $color-default-black;
          }
        }
      }
    }

    .news-card__image-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 56.5%;
      height: 0;

      @include vp-1187 {
        padding-bottom: 75%;
      }

      @include vp-1023 {
        padding-bottom: 80%;
      }

      @include vp-767 {
        padding-bottom: 86.5%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: transform $trans-default;
      }
    }

    .news-card__title {
      max-width: 90%;

      font-weight: 700;
      font-size: size(24px);
      line-height: 130%;
      color: $color-default-black;

      letter-spacing: -0.01em;
      text-decoration: underline;
      text-decoration-color: transparent;

      display: -webkit-box;
      overflow: hidden;

      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      transition: text-decoration $trans-default;;

      @include vp-1919 {
        font-size: 24px;
      }

      @include vp-1023 {
        max-width: 100%;
      }

      @include vp-767 {
        font-size: 14px;
      }
    }

    .news-card__lead {
      max-width: 84%;
      margin: size(30px) 0 0;
      font-weight: 400;
      font-size: size(20px);
      line-height: 116%;
      color: $color-default-black;

      display: -webkit-box;
      overflow: hidden;

      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include  vp-1919 {
        font-size: 20px;
        margin: 30px 0 0;
      }

      @include  vp-1187 {
        font-size: 20px;
        margin: 16px 0 0;
      }

      @include  vp-1023 {
        font-size: 18px;
        line-height: 120%;
        -webkit-line-clamp: 3;
      }

      @include  vp-767 {
        max-width: 100%;
        font-size: 12px;
        line-height: 120%;
        margin: 10px 0 18px;
        -webkit-line-clamp: 4;
      }
    }

    .news-card__content-wrapper {
      padding: size(30px) 0 0;

      @include vp-1919 {
        padding: 30px 0 0;
      }

      @include vp-1023 {
        padding: 20px 0 0;
      }

      @include vp-767 {
        padding: 14px 0 0;
      }
    }
  }
}
