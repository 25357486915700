.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: size(76px);
  margin-bottom: size(58px);

  @include vp-1919 {
    margin-top: 76px;
    margin-bottom: 58px;
  }

  @include vp-1023 {
    margin-top: 80px;
    margin-bottom: 76px;
  }

  @include vp-767 {
    margin-top: 50px;
    margin-bottom: 64px;
  }

  &__btn-prev,
  &__btn-next {
    width: size(100px);
    height: size(100px);

    @include vp-1919 {
      width: 100px;
      height: 100px;
    }

    @include vp-1023 {
      width: 60px;
      height: 60px;
    }

    @include vp-767 {
      width: 52px;
      height: 52px;
    }

    svg {
      width: size(42px);
      height: size(24px);

      @include vp-1919 {
        width: 42px;
        height: 24px;
      }

      @include vp-1023 {
        width: 31px;
        height: 16px;
      }

      @include vp-767 {
        width: 25px;
        height: 8px;
      }
    }

    &.disabled {
      opacity: 0.5;

      pointer-events: none;
    }

    @include hover-focus {
      background-color: $color-white-smoke;
    }
  }

  &__list {
    margin: 0 size(5px) 0 size(5px);
    padding: 0;
    list-style: none;

    display: flex;
  }

  &__item {
    width: size(100px);
    height: size(100px);
    margin: 0 size(5px);

    border: size(2px) solid $color-white-smoke;
    transition: opacity $trans-default, background-color $trans-default;

    @include vp-1919 {
      width: 100px;
      height: 100px;
      margin: 0 5px;

      border-width: 2px;
    }

    @include vp-1023 {
      width: 60px;
      height: 60px;
    }

    @include vp-767 {
      width: 52px;
      height: 52px;
    }

    &.active {
      background-color: $color-white-smoke;
      pointer-events: none;
    }

    @include hover-focus {
      background-color: $color-white-smoke;
    }
  }

  &__item--ellipse {}

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    font-weight: 400;
    font-size: size(36px);
    line-height: 100%;

    @include vp-1919 {
      font-size: 36px;
    }

    @include vp-1023 {
      font-size: 24px;
      line-height: 116%;
    }

    @include vp-767 {
      font-size: 18px;
    }
  }

  &__mobile-none {
    @include vp-767 {
      display: none;
    }
  }
}
