.logo {
  display: flex;
  align-items: center;

  color: $color-default-black;

  transition: opacity $trans-default, color $trans-default;

  svg {
    width: size(256px);
    height: size(50px);

    @include vp-1919 {
      width: 256px;
      height: 50px;
    }

    @include vp-767 {
      width: 144px;
      height: 28px;
    }
  }

  &__text {
    @include visually-hidden;
  }
}

.is-active .logo {
  color: $color-default-white;
}
