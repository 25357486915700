.page-section {
  padding-bottom: size(60px);

  @include vp-1919 {
    padding-bottom: 60px;
  }

  @include vp-1187 {
    padding-bottom: 42px;
  }

  @include vp-1023 {
    padding-bottom: 44px;
  }

  @include vp-767 {
    padding-bottom: 6px;
  }

  &:first-of-type {
    padding-top: size(40px);

    @include vp-1919 {
      padding-top: 40px;
    }

    @include vp-767 {
      padding-top: 8px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    padding-bottom: size(15px);
    margin-bottom: size(10px);
    border-bottom: size(2px) solid $color-whisper;

    @include vp-1919 {
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-width: 2px;
    }

    @include vp-767 {
      padding-bottom: 12px;
      margin-bottom: 10px;
    }

    &.mb-0 {
      @include vp-767 {
        margin-bottom: 0;
      }
    }


    &.no-border-bottom {
      border-bottom: none;
    }
  }

  &__title {
    margin: 0;
    font-weight: 700;
    font-size: size(24px);
    line-height: 100%;

    @include vp-1919 {
      font-size: 24px;
    }

    @include vp-767 {
      font-size: 14px;
    }
  }

  &__title--h1 {
    font-size: size(48px);
    line-height: 100%;
    letter-spacing: size(-1.2px);

    @include vp-1919 {
      font-size: 48px;
      letter-spacing: -0.2px;
    }

    @include vp-1187 {
      font-size: 36px;
      line-height: 48px;
    }

    @include vp-1023 {
      font-size: 24px;
      line-height: 112%;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 112%;
    }
  }

  &__title--about {
    font-size: size(48px);
    line-height: 100%;
    letter-spacing: size(-1.2px);

    @include vp-1919 {
      font-size: 48px;
      letter-spacing: -0.2px;
    }

    @include vp-1023 {
      font-size: 36px;
      line-height: 112%;
    }

    @include vp-767 {
      font-size: 22px;
      line-height: 112%;

      margin-bottom: -6px;
    }
  }

  &__section-link {
    font-weight: 400;
    font-size: size(18px);
    letter-spacing: -0.05em;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity $trans-default;

    @include vp-1919 {
      font-size: 18px;
    }

    @include vp-767 {
      font-size: 10px;
      letter-spacing: -0.03em;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: .7;
      }

      &:active {
        opacity: .7;
      }
    }
  }

  &__mail {
    display: inline-flex;
    padding-top: size(14px);
    font-weight: 700;
    font-size: size(32px);
    line-height: 100%;
    text-decoration: underline;
    transition: opacity $trans-default;

    @include vp-1919 {
      padding-top: 14px;
      font-size: 32px;
    }

    @include vp-1187{
      padding-top: 4px;
    }

    @include vp-1023 {
      padding-top: 18px;
      font-size: 24px;
    }

    @include vp-767{
      padding-top: 0;
      font-size: 14px;
    }

    @include hover-focus {
      opacity: .6;
    }
  }

  &--intro {
    min-height: size(922px);

    @include  vp-1919 {
      min-height: 922px;
    }

    @include  vp-1187 {
      min-height: auto;
    }
  }

  &--intro-eng {
    min-height: size(922px);

    @include  vp-1919 {
      min-height: 922px;
    }

    @include  vp-1187 {
      min-height: auto;
    }

    .news-card--big {
      @include vp-1619 {
        grid-column: span 12;
      }

      @include vp-1187 {
        grid-column: span 2;
      }
    }
  }

  &__body--smoke-bg {
    background-color: $color-white-smoke;
  }

  &__body--whisper {
    background-color: $color-whisper;
  }

  &--socials {
    padding-top: size(18px);

    @include vp-1919 {
      padding-top: 18px;
    }

    @include vp-1187 {
      padding-top: 0;
      padding-bottom: 80px;
    }

    @include vp-767 {
      padding-bottom: 34px;
    }

    .container {
      display: flex;
      justify-content: space-between;

      @include vp-1619 {
        flex-direction: column;
        align-items: center;
      }
    }

    .page-section__header {
      margin: 0;
      margin-right: size(40px);
      padding: 0;

      align-items: center;
      border-bottom: none;

      @include vp-1919 {
        margin-right: 40px;
      }

      @include vp-1187 {
        margin-right: 0;
        margin-bottom: 40px;
      }

      @include vp-1023 {
        margin-bottom: 28px;
      }
    }

    .page-section__title {
      font-weight: 700;
      font-size: size(48px);
      line-height: size(58px);

      @include vp-1919 {
        font-size: 48px;
        line-height: 58px;
      }

      @include vp-1187 {
        text-align: center;
        letter-spacing: -0.019em;
      }

      @include vp-1023 {
        font-size: 36px;
        line-height: 44px;
      }

      @include vp-767 {
        font-size: 22px;
        line-height: 27px;
      }
    }
  }

  &--line {
    .page-section__header {
      @include vp-767 {
        margin-bottom: 6px;
      }
    }
  }

  &--pb-0 {
    padding-bottom: 0;
  }

  &--category.page-section:first-of-type {
    @include vp-1187 {
      padding-top: 22px;
    }

    @include vp-1023 {
      padding-top: 4px;
    }

    @include vp-767 {
      padding-top: 0;
    }
  }

  &--category {
    .page-section__header {
      @include vp-1187 {
        padding-bottom: 10px;
      }

      @include vp-767 {
        padding-bottom: 8px;
      }
    }
  }

  &.py-64 {
    padding-top: size(64px);
    padding-bottom: size(46px);

    @include vp-1919 {
      padding-top: 64px;
      padding-bottom: 46px;
    }

    @include vp-1187 {
      padding-top: 60px;
      padding-bottom: 64px;
    }

    @include vp-1023 {
      padding-top: 40px;
      padding-bottom: 44px;
    }

    @include vp-767 {
      padding-top: 54px;
      padding-bottom: 26px;
    }
  }
}
