.main-article {
  position: relative;

  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: size(50px);

  @include vp-1919 {
    padding-top: 50px;
  }

  @include vp-1279 {
    padding-top: 10px;
  }

  @include vp-767 {
    padding-top: 20px;
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__meta-wrapper {
  }

  &__title {
    margin: 0 0 size(32px);

    font-weight: 700;
    font-size: size(48px);
    line-height: 100%;
    letter-spacing: size(-1.2px);

    @include vp-1919 {
      margin: 0 0 32px;
      font-size: 48px;
      letter-spacing: -1.2px;
    }

    @include vp-1279 {
      margin: 0 0 16px;
      font-size: 40px;
      line-height: 48px;
    }

    @include vp-1023 {
      max-width: 96%;
      margin: 0 0 20px;
      font-size: 36px;
    }

    @include vp-767 {
      margin: 0 0 12px;
      font-size: 22px;
      line-height: 118%;
    }
  }

  &__author {
    display: flex;
    padding-bottom: size(30px);

    font-weight: 400;
    font-size: size(18px);
    line-height: size(22px);

    @include vp-1919 {
      padding: 0 0 30px;
      font-size: 18px;
      line-height: 22px;
    }

    @include vp-1023 {
      padding: 16px 0 28px;
    }

    @include vp-767 {
      padding: 16px 0 20px;
      font-size: 12px;
      line-height: 120%;
    }

    span {
      margin-right: size(4px);
      color: $color-nobel;

      @include vp-1919 {
        margin-right: 4px;
      }
    }
  }

  &__author-bold {
    font-weight: 700;
    font-size: size(24px);
    line-height: size(31px);

    @include vp-1919 {
      padding: 0 0 30px;
      font-size: 24px;
      line-height: 31px;
    }

    @include vp-1023 {
      padding: 16px 0 28px;
    }

    @include vp-767 {
      padding: 16px 0 20px;
      font-size: 12px;
      line-height: 120%;
    }
  }

  &__author-link {
    font-weight: 400;
    font-size: size(18px);
    line-height: size(22px);
    color: $color-mariner;
    border-bottom: size(1px) solid transparent;

    outline: none;
    cursor: pointer;
    transition: border-color $trans-default;

    @include vp-1919 {
      font-size: 18px;
      line-height: 22px;
      border-width: 1px;
    }

    @include vp-767 {
      font-size: 12px;
      line-height: 120%;
    }

    cite {
      font-style: normal;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-bottom-color: $color-default-black;
      }

      &:active {
        border-bottom-color: $color-transparent;
      }
    }
  }

  &__picture {
    margin: 0;
  }

  &__img-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 55%;

    @include vp-1187 {
      padding-bottom: 61%;
    }

    @include vp-1023 {
      padding-bottom: 58%;
    }

    @include vp-767 {
      padding-bottom: 62%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__img-description {
    margin-top: size(24px);

    @include vp-1919 {
      margin-top: 24px;
    }

    @include vp-1023 {
      margin-top: 14px;
    }

    @include vp-767 {
      margin-top: 8px;
    }
  }

  &__description-text {
    margin: 0;
    font-size: size(18px);
    line-height: size(22px);
    letter-spacing: size(-0.2px);
    opacity: .6;

    @include vp-1919 {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.2px;
    }

    @include vp-1023 {
      font-size: 14px;
      line-height: 19px;
    }

    @include vp-767 {
      font-size: 12px;
      line-height: 120%;
    }
  }
}
