.recommend {
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: size(30px);

    padding: size(60px) 0 size(20px);

    @include vp-1919 {
      gap: 30px;

      padding: 60px 0 20px;
    }

    @include vp-1187 {
      grid-template-columns: 1fr;
      padding: 60px 0 28px;
      margin-bottom: 20px;
    }

    @include vp-1023 {
      grid-template-columns: 1fr;
      padding: 50px 0 38px;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      gap: 22px;

      padding: 30px 0 12px;
      margin-top: 6px;
      margin-bottom: 26px;
    }
  }

  &__news-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include vp-1187 {
      margin-top: 22px;
    }

    @include vp-1023 {
      margin-top: 4px;
    }
  }

  &__item {
    margin-bottom: size(30px);

    @include vp-1919 {
      margin-bottom: 30px;
    }

    @include vp-1023 {
      margin-bottom: 24px;
    }
  }
}
