.language-toggle {
  form {
    display: flex;
    align-items: center;
  }

  &__btn {
    display: grid;
    grid-template-columns: auto 1fr;
    flex-shrink: 0;
    align-items: end;
    padding: size(1px) 0 size(1px) size(6px);
    transition: opacity $trans-default;
    cursor: pointer;

    border: none;

    @include vp-1919 {
      padding: 1px 0 1px 6px;
    }

    @include vp-1187 {
      background-color: transparent;
    }

    @include vp-767 {
      align-items: center;
    }

    &:not(:last-child) {
      margin-right: size(13px);

      @include vp-1919 {
        margin-right: 13px;
      }

      @include vp-1187 {
        margin-right: 12px;
      }

      @include vp-767 {
        margin-right: 32px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        .language-toggle__text {
          border-color: $color-default-black;
        }

        @include vp-1187 {
          .language-toggle__text {
            border-color: $color-default-white;
          }
        }
      }

      &:active {
        .language-toggle__text {
          border-color: $color-default-black;
        }

        @include vp-1187 {
          .language-toggle__text {
            border-color: $color-default-white;
          }
        }
      }
    }
  }

  img {
    width: size(24px);
    height: size(24px);
    border-radius: 50%;

    @include vp-1919 {
      width: 24px;
      height: 24px;
    }
  }

  .language-toggle__text {
    margin-left: size(5px);
    font-weight: 400;
    font-size: size(18px);
    line-height: 100%;
    border-bottom: size(1px) solid transparent;
    transition: border $trans-default;

    @include vp-1919 {
      margin-left: 5px;
      font-size: 18px;
      border-width: 1px;
    }

    @include vp-1187 {
      color: $color-default-white;
    }

    @include vp-767 {
      font-size: 14px;
    }
  }
}

.is-active .language-toggle--header {
  display: none;
}

.language-toggle--header {
  display: none;

  @include vp-1187 {
    display: flex;
    margin-right: 64px;

    z-index: 7;

    .language-toggle__btn {
      background-color: $color-default-white;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          @include vp-1187 {
            .language-toggle__text {
              border-color: $color-default-black;
            }
          }
        }

        &:active {
          @include vp-1187 {
            .language-toggle__text {
              border-color: $color-default-black;
            }
          }
        }
      }
    }

    .language-toggle__text {
      color: $color-default-black;
    }
  }

  @include vp-767 {
    display: none;
  }
}

.language-toggle--footer {
  .language-toggle__text {
    @include vp-1187 {
      color: $color-default-black;
    }
  }

  .language-toggle__btn {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        @include vp-1187 {
          .language-toggle__text {
            border-color: $color-default-black;
          }
        }
      }

      &:active {
        @include vp-1187 {
          .language-toggle__text {
            border-color: $color-default-black;
          }
        }
      }
    }
  }
}

.language-toggle--article {
  .language-toggle__btn {
    margin-right: 0;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        opacity: .5;
      }

      &:active {
        opacity: .5;
      }
    }
  }

  .language-toggle__text {
    display: none;
  }

  img {
    width: 19px;
    height: 19px;
  }
}
