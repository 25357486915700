.news-block {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: size(30px);

    @include vp-1919 {
      gap: 30px;
    }

    @include vp-1187 {
      grid-template-columns: 1fr;
      gap: 30px 0;
      padding-bottom: 22px;
    }

    @include vp-1023 {
      padding-bottom: 16px;
    }

    @include vp-767 {
      gap: 10px;

      padding-bottom: 26px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: size(30px);

    grid-column: 1;

    @include vp-1919 {
      gap: 30px;
    }

    @include vp-1187 {
      gap: 20px;
    }

    @include vp-767 {
      gap: 10px;
    }
  }

  .left {
    grid-column: 1;

    @include vp-1187 {
      grid-column: span 2;
    }
  }

  .right {
    grid-column: 2;

    @include vp-1187 {
      grid-column: span 2;
      //grid-row: 2;
    }
  }

  &__big-card {
    //grid-column: 2;
    grid-row: 1 / -1;

    @include vp-1187 {
      grid-column: span 2;
      grid-row: 1;
    }

    .news-card__image-wrapper {
      position: relative;
      padding-bottom: 60%;
      height: 0;
      width: 100%;
      overflow: hidden;

      @include vp-1619 {
        padding-bottom: 69%;
      }

      @include vp-1187 {
        padding-bottom: 61%;
      }

      @include vp-1023 {
        padding-bottom: 62.5%;
      }

      @include vp-767 {
        padding-bottom: 83.5%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        height: 100%;
        transition: transform $trans-default;
      }
    }

    .news-card__title {
      margin-top: size(10px);

      font-size: size(48px);
      line-height: size(58px);

      @include vp-1919 {
        margin-top: 22px;

        font-size: 48px;
        line-height: 58px;
      }

      @include vp-1187 {
        margin-top: 12px;
      }

      @include vp-1023 {
        margin-top: 16px;

        font-size: 36px;
        line-height: 44px;
      }

      @include vp-767 {
        margin-top: 2px;

        font-size: 22px;
        line-height: 27px;
      }
    }

    .news-card__content-wrapper {
      padding: size(20px) size(40px) size(60px) size(60px);

      @include vp-1919 {
        padding: 20px 40px 64px 60px;
      }

      @include vp-1187 {
        padding: 20px 40px 70px 65px;
      }

      @include vp-1023 {
        padding: 20px 20px 50px;
      }

      @include vp-767 {
        padding: 20px 10px 26px;
      }
    }
  }
}
