.about {
  position: relative;

  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: size(26px);

  @include vp-1919 {
    padding-top: 26px;
  }

  @include vp-1279 {
    padding-top: 24px;
  }

  @include vp-1023 {
    padding-top: 6px;
  }

  @include vp-767 {
    padding-top: 0;
  }

  &__header {
    display: flex;
    flex-direction: column;

    margin-bottom: size(24px);

    @include vp-1919 {
      margin-bottom: 24px;
    }

    @include vp-1187 {
      max-width: 87%;

      margin-bottom: 28px;
    }

    @include vp-1023 {
      max-width: 85%;
      margin-bottom: 12px;
    }

    @include vp-767 {
      max-width: 100%;
      margin-bottom: 26px;
    }
  }

  &__title {
    margin: 0;

    font-weight: 700;
    font-size: size(48px);
    line-height: 100%;
    letter-spacing: size(-1.2px);

    @include vp-1919 {
      font-size: 48px;
      letter-spacing: -1.2px;
    }

    @include vp-1023 {
      font-size: 36px;
      line-height: 120%;
    }

    @include vp-767 {
      font-size: 22px;
      line-height: 118%;
    }
  }

  &__body {
    margin-bottom: size(-4px);

    @include vp-1919 {
      margin-bottom: -4px;
    }

    @include vp-767 {
      margin-bottom: -20px;
    }
  }

  &__author {
    font-weight: 700;
    font-size: size(24px);
    line-height: size(31px);

    @include vp-1919 {
      padding: 0 0 30px;
      font-size: 24px;
      line-height: 31px;
    }

    @include vp-1187 {
      padding: 0;
    }

    @include vp-1023 {
      padding: 16px 0 0;
      font-size: 18px;
    }

    @include vp-767 {
      padding: 0;
      font-size: 12px;
      line-height: 122%;
    }
  }
}
