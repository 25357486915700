.content-wrapper {
  max-width: 96%;
  padding-top: size(40px);
  padding-bottom: size(24px);

  &:first-of-type {
    @include vp-767 {
      padding-top: 8px;
    }
  }

  &:last-of-type {
    @include vp-767 {
      padding-bottom: 35px;
    }
  }

  @include vp-1919 {
    padding-top: 40px;
    padding-bottom: 24px;
  }

  @include vp-1187 {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  @include vp-1023 {
    max-width: 95%;
    padding-bottom: 0;
  }

  @include vp-767 {
    padding-top: 24px;
    padding-bottom: 0;
  }

  p {
    margin: 0;
    margin-bottom: size(14px);
    font-size: size(24px);
    line-height: size(31px);
    color: $color-default-black;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include vp-1919 {
      margin-bottom: 14px;
      font-size: 24px;
      line-height: 31px;
    }

    @include vp-1023 {
      font-size: 18px;
      line-height: 31px;
    }

    @include vp-767 {
      font-size: 16px;
      line-height: 121%;
      margin-bottom: 17px;
    }
  }

  &__lead {
    font-weight: 400;
    font-size: size(28px);
    line-height: size(35px);
    letter-spacing: size(-0.2px);
    color: $color-default-black;

    @include vp-1919 {
      font-size: 28px;
      line-height: 35px;
      letter-spacing: -1.2px;
    }

    @include vp-1279 {
      letter-spacing: -0.2px;
    }

    @include vp-1023 {
      font-size: 24px;
      line-height: 35px;
    }

    @include vp-767 {
      font-size: 18px;
      line-height: 120%;
    }

    p {
      font-weight: 400;
      font-size: size(28px);
      line-height: size(35px);
      letter-spacing: size(-0.2px);
      color: $color-default-black;

      @include vp-1919 {
        font-size: 28px;
        line-height: 35px;
        letter-spacing: -1.2px;
      }

      @include vp-1279 {
        letter-spacing: -0.2px;
      }

      @include vp-1023 {
        font-size: 24px;
        line-height: 35px;
      }

      @include vp-767 {
        font-size: 18px;
        line-height: 120%;
      }
    }
  }

  &--mobile-12 {
    @include vp-767 {
      max-width: 100%;

      p {
        font-size: 12px;
        line-height: 122%;
      }
    }
  }
}
