.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  color: $color-default-black;

  background-color: $color-default-white;
  border: size(2px) solid $color-white-smoke;
  cursor: pointer;

  transition: opacity $trans-default, background-color $trans-default;

  @include vp-1919 {
    border-width: 2px;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  //@include hover-focus {
  //  background-color: $color-white-smoke;
  //}
}
