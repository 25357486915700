.meta {
  display: inline-flex;
  align-items: center;
  color: $color-zorba;

  &__icon {
    width: size(16px);
    height: size(16px);
    margin-right: size(10px);

    @include vp-1919 {
      width:16px;
      height: 16px;
      margin-right: 10px;
    }

    @include vp-767 {
      width:12px;
      height: 12px;
      margin-right: 8px;
    }
  }

  &__date{
    font-weight: 400;
    //font-size: size(14px);
    //line-height: size(15px);
    //
    //@include vp-1919 {
    //  font-size: 14px;
    //  line-height: 15px;
    //}
    //
    //@include vp-1187 {
    //  font-size: 18px;
    //  line-height: 22px;
    //}
    //
    //@include vp-767 {
    //  font-size: 12px;
    //  line-height: 15px;
    //}
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: size(20px);
    padding-right: size(20px);
    border-right: size(1px) solid $color-nobel;

    @include vp-1919 {
      margin-right: 20px;
      padding-right: 20px;
      border-right: 1px solid $color-nobel;
    }

    @include vp-767 {
      margin-right: 18px;
      margin-bottom: 8px;
      padding-right: 18px;
    }

    &:last-child {
      @include vp-767 {
        margin-right: 0;
        padding-right: 0;
        border-right: none;
      }
    }
  }

  &__category,
  &__content,
  &__span {
    font-weight: 400;
    font-size: size(18px);
    line-height: size(22px);
    color: $color-nobel;
    border-bottom: size(1px) solid transparent;

    @include vp-1919 {
      font-size: 18px;
      line-height: 22px;
      border-width: 1px;
    }

    @include vp-767 {
      font-size: 12px;
      line-height: 15px;
    }
  }

  &__category {
    transition: border-color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-bottom-color: $color-default-black;
      }

      &:active {
        border-bottom-color: $color-transparent;
      }
    }
  }

  &__span {
    margin-right: 8px;
  }

  &__item--no-desktop {
    display: none;

    @include vp-767 {
      display: flex;
    }
  }

  &__item--no-mobile {
    @include vp-767 {
      display: none;
    }
  }
}
