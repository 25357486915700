.socials {
  display: grid;
  grid-template-columns: repeat(5, min-content);

  margin: 0;
  padding: 0;

  list-style: none;

  &--header {
    @include vp-1187 {
      display: none;
    }
  }

  &--footer {
    .socials__item {
      margin-right: size(22px);

      @include vp-1919 {
        margin-right: 22px;
      }

      @include vp-767 {
        margin-right: 10px;
      }
    }
  }

  &--big-icons {
    @include vp-767 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .socials__item {
      margin: 0 size(40px);

      @include vp-1919 {
        margin: 0 40px;
      }

      @include vp-1023 {
        margin: 0 22px;
      }

      @include vp-767 {
        margin: 0 10px 10px;
      }
    }

    .socials__link {
      padding: size(10.5px) size(10.5px);
      background-color: $color-default-white;

      svg {
        width: size(67px);
        height: size(67px);

        transform: translate3d(0, 0, 0);
        transition: opacity $trans-default;
      }

      @include vp-1919 {
        padding: 10.5px 10.5px;

        svg {
          width: 67px;
          height: 67px;
        }
      }

      @include vp-1023 {
        svg {
          width: 64px;
          height: 64px;
        }
      }

      @include vp-767{
        svg {
          width: 43px;
          height: 43px;
        }
      }
    }
  }
}

.socials__item {
  position: relative;
  margin-right: size(17px);

  background: $color-white-smoke;

  &:last-child {
    margin-right: 0;

    &::before {
      display: none;
    }
  }

  @include vp-1919 {
    margin-right: 17px;
  }
}

.socials__link {
  display: flex;
  padding: size(10.5px) size(10.5px);

  svg {
    width: size(24px);
    height: size(24px);

    transform: translate3d(0, 0, 0);
    transition: opacity $trans-default;
  }

  @include vp-1919 {
    padding: 10.5px 10.5px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        opacity: 0.6;
      }
    }

    &:active {
      svg {
        opacity: 0.6;
      }
    }
  }
}
