.news-card-block {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  gap: 30px;

  grid-column: span 3;
  grid-row: span 2;

  @include vp-1619 {
    grid-column: span 4;
    grid-row: span 1;
  }

  @include vp-1187 {
    grid-template-columns: repeat(2, 1fr);

    grid-column: span 2;
    grid-row: 2;
  }

  @include vp-1023 {
    gap: 20px;
  }

  @include vp-767 {
    grid-template-columns: 1fr;

    grid-column: 1;
    grid-row: 2;
    gap: 10px;
  }

  &--4x4 {
    grid-template-columns: repeat(2, 1fr);
    grid-column: span 6;

    @include vp-1619 {
      grid-template-columns: repeat(2,1fr);
      grid-column: span 12;
    }

    @include vp-1187 {
      grid-column: span 2;
    }

    @include vp-767 {
      grid-template-columns: 1fr;

      grid-column: 1;
      grid-row: 2;
      gap: 10px;
    }
  }
}
