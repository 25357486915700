.news-in-line {

  &__list {
    display: flex;
    flex-wrap: wrap;

    list-style: none;
  }

  &--4x1 {
    .news-in-line__list {
      margin: 0 size(-15px);
      padding: size(60px) 0;

      @include vp-1919 {
        margin: 0 -15px;
        padding: 60px 0;
      }

      @include vp-1619 {
        margin: 0 -10px;
        padding: 60px 0;
      }

      @include vp-1187 {
        padding: 45px 0;
      }

      @include vp-1023 {
        padding: 60px 0;
      }

      @include vp-767 {
        margin: 0 -5px 28px;
        padding: 10px 0 20px;
      }
    }

    .news-in-line__item {
      width: calc(25% - #{size(30px)});
      margin: 0 size(15px);

      @include vp-1919 {
        width: calc(25% - 30px);
        margin: 0 15px;
      }

      @include vp-1619 {
        width: calc(25% - 20px);
        margin: 0 10px;
      }

      @include vp-1187 {
        width: calc(50% - 20px);
        margin: 15px 10px;
      }

      @include vp-767 {
        width: calc(50% - 10px);
        margin: 15px 5px;
      }
    }
  }

  &--3x2 {
    .news-in-line__list {
      margin: 0 size(-15px);
      padding: size(60px) 0  0;

      @include vp-1919 {
        margin: 0 -15px;
        padding: 60px 0 0;
      }

      @include vp-1187 {
        margin: 0 -10px;
        padding: 50px 0 40px;
      }

      @include vp-1023 {
        padding: 34px 0 38px;
      }

      @include vp-767 {
        margin: 0 -5px;
        padding: 3px 0 32px;
      }
    }

    .news-in-line__item {
      width: calc((100%/3) - #{size(30px)});
      margin: 0 size(15px) size(50px);

      @include vp-1919 {
        width: calc((100%/3) - 30px);
        margin: 0 15px 50px;
      }

      @include vp-1187 {
        width: calc(50% - 20px);
        margin: 15px 10px 38px;
      }

      @include vp-1023 {
        margin-bottom: 30px;
      }

      @include vp-767 {
        width: calc(50% - 10px);
        margin: 15px 5px;
      }
    }
  }
}
