.search-form {
  position: relative;

  display: flex;
  align-items: center;
  width: size(480px);
  margin-left: size(32.5px);

  @include vp-1919 {
    width: 480px;
    margin-left: 32.5px;
  }

  @include vp-1279 {
    width: 252px;
  }

  @include vp-1187 {
    display: none;
    position: absolute;
    right: 84px;

    width: 175px;
    height: 50px;
    margin-right: 20px;
    z-index: 7;
    transition: width $trans-default;
  }

  @include vp-767 {
    position: static;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  &.is-active {
    @include vp-1187 {
      width: 88%;
      background-color: $color-nero;
      z-index: 10;
    }

    @include vp-1023 {
      width: 84%;
      background-color: $color-nero;
      z-index: 10;
    }
  }

  &.is-active::before {
    display: none;

    @include vp-1187 {
      display: block;
      position: absolute;
      content: "";
      left: -32px;
      top: -25px;
      width: 100px;
      height: 80px;
      background-color: $color-nero;
    }
  }

  form {
    width: 100%;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          opacity: 0.6;
        }
      }

      &:active {
        svg {
          opacity: 0.6;
        }
      }
    }
  }

  label {
    @include visually-hidden;
  }

  input[type="search"] {
    display: inline-block;
    vertical-align: middle;

    width: 100%;
    height: size(43px);
    padding-left: size(51px);
    padding-right: size(20px);

    font-size: size(14px);
    line-height: 120%;

    outline: none;
    border: none;

    transition: background-color $trans-default;

    &::placeholder {
      color: $color-default-black;
    }

    @include vp-1919 {
      height: 43px;
      padding-left: 51px;
      padding-right: 20px;

      font-size: 14px;
    }

    @include vp-1187 {
      height: 42px;
      padding-left: 49px;

      color: $color-default-white;
      background-color: $color-nero;

      &::placeholder {
        color: $color-default-white;
        opacity: 0.6;
      }
    }
  }

  &__search-wrapper {
    position: relative;

    border: size(2px) solid $color-default-black;

    @include vp-1919 {
      border-width: 2px;
    }

    @include vp-1187 {
      border-color: $color-default-white;
    }
  }

  .search-form__search-submit {
    position: absolute;
    left: size(12px);
    top: calc((100% - size(33px)) / 2);
    z-index: 4;

    display: inline-block;
    vertical-align: middle;

    padding: size(6px) size(8px);

    color: $color-default-black;
    background: $color-transparent;
    border: none;
    outline: none;
    border-radius: size(8px);

    cursor: pointer;
    transition: opacity $trans-default;

    @include vp-1919 {
      top: calc((100% - 33px) / 2);
      left: 12px;
      padding: 6px 8px;
      border-radius: 8px;
    }

    @include vp-1187 {
      color: $color-default-white;
    }

    svg {
      width: size(17px);
      height: size(17px);
      vertical-align: middle;

      @include vp-1919 {
        width: 17px;
        height: 17px;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          opacity: 0.6;
        }
      }

      &:active {
        svg {
          opacity: 0.6;
        }
      }
    }
  }
}

.is-active .search-form {
  @include vp-1187 {
    display: flex;
  }
}
