.container {
  width: 100%;
  max-width: size(1920px);
  margin: 0 auto;
  padding: 0 size(30px);

  @include vp-1919 {
    max-width: 1920px;
    padding: 0 30px;
  }

  @include vp-1279 {
    padding: 0 20px;
  }

  @include vp-767 {
    padding: 0 10px;
  }

  &--py-345 {
    padding: 0 size(345px);

    @include vp-1919 {
      max-width: 1230px;
      padding: 0 30px;
    }

    @include vp-1279 {
      padding: 0 20px;
    }

    @include vp-767 {
      padding: 0 10px;
    }
  }
}
