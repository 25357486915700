.footer {
  padding: size(58px) 0;

  background-color: $color-white-smoke;

  @include vp-1919 {
    padding: 58px 0;
  }

  @include vp-767 {
    padding: 50px 0 35px;
  }

  .container {
    display: grid;
    justify-content: center;

    @include vp-767 {
      display: block;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__wrapper--top {
    padding: size(24px) 0 size(28px);

    @include vp-1919 {
      padding: 24px 0 28px;
    }

    @include vp-767 {
      flex-direction: column;
      margin-bottom: 35px;
      padding: 0;
    }
  }

  &__wrapper--middle {
    margin-bottom: size(40px);

    @include vp-1919 {
      margin-bottom: 40px;
    }

    @include vp-767 {
      margin-bottom: 30px;
    }
  }

  &__wrapper--rights {
    flex-direction: column;
    padding-top: size(24px);

    @include vp-1919 {
      padding-top: 24px;
    }

    @include vp-767 {
      padding-top: 0;
    }
  }

  &__nav-list {
    display: flex;
    list-style: none;
    margin: 0 size(40px) 0 0;
    padding: 0;

    @include vp-1919 {
      margin: 0 40px 0 0;
    }

    @include vp-767 {
      margin: 0 0 17px;
    }
  }

  &__nav-link {
    font-weight: 400;
    font-size: size(18px);
    line-height: 100%;
    white-space: nowrap;
    color: $color-default-black;
    border-bottom: size(1px) solid transparent;

    outline: none;

    transition: border-color $trans-default;
    user-select: none;
    cursor: pointer;

    @include vp-1919 {
      font-size: 18px;
      border-bottom: 1px solid transparent;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 17px;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        border-color: $color-default-black;
      }

      &:active {
        border-color: $color-default-black;
      }
    }
  }

  &__nav-item:not(:last-child) {
    margin-right: size(52px);

    @include vp-1919 {
      margin-right: 52px;
    }

    @include vp-767 {
      margin-right: 36px;
    }
  }

  &__rights,
  &__reg-info {
    font-size: size(14px);
    line-height: size(24px);
    margin: 0;

    @include vp-1919 {
      font-size: 14px;
      line-height: 24px;
    }

    @include vp-767 {
      text-align: center;
    }
  }

  &__rights {
    @include vp-767 {
      margin-bottom: 40px;
    }
  }

  &__reg-info {
    opacity: .6;
    margin-bottom: size(30px);

    @include vp-1919 {
      margin-bottom: 30px;
    }

    @include vp-767 {
      max-width: 88%;
      margin-bottom: 20px;

      font-size: 10px;
      line-height: 15px;
    }
  }

  &__years {
    margin: 0;
    font-size: size(18px);
    line-height: size(24px);

    @include vp-1919 {
      font-size: 18px;
      line-height: 24px;
    }

    @include vp-767 {
      font-size: 10px;
    }
  }

  .only-mobile {
    display: none;

    @include vp-767 {
      display: block;
    }
  }
}
