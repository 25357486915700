.dropdown {
  position: absolute;
  z-index: 3;

  display: table;
  width: 100%;
  min-width: max-content;
  margin: 0;
  padding: size(29px) size(31px) size(41px) size(40px);

  background-color: $color-white-smoke;
  list-style: none;

  transition: opacity $trans-default, transform $trans-default, background-color $trans-default;
  transform-origin: center top;
  transform: translate3d(size(-110px), size(16px), 0) rotateX(-30deg);

  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  //z-index: -1;
  animation: disableBreakpointAnimation 0.2s ease;

  @include vp-1919 {
    padding: 29px 31px 41px 40px;

    transform: translate3d(-110px, 16px, 0) rotateX(-30deg);
  }

  @include vp-1187 {
    position: static;
    display: block;
    padding: 28px 16px 16px 0;

    transform: none;
    opacity: 1;
    visibility: visible;

    background-color: transparent;
  }

  @include vp-1023 {
    padding-top: 26px;
    padding-right: 0;
  }

  @include vp-767 {
    min-width: 0;
    width: 78%;
    margin: 0 auto;
    padding-top: 18px;
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    top: size(-18px);

    height: size(18px);

    content: "";

    @include vp-1919 {
      top: -18px;

      height: 18px;
    }

    @include vp-1187 {
      content: none;
    }
  }
}

.dropdown__list {
  margin: 0;
  padding: 0;

  list-style: none;
  transform: perspective(1px) translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;

  @include vp-767 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dropdown__item,
.dropdown .main-nav__item {
  min-width: size(401px);
  line-height: 0;

  @include vp-1919 {
    min-width: size(401px);
  }

  @include vp-767 {
    min-width: 0;
  }

  &:not(:last-child) {
    margin-bottom: size(10px);

    @include vp-1919 {
      margin-bottom: 10px;
    }

    @include vp-1187 {
      margin-bottom: 14px;
    }
  }
}

.dropdown__link,
.dropdown .main-nav__link {
  display: inline-block;
  vertical-align: baseline;

  font-weight: 400;
  font-size: size(18px);
  line-height: 100%;
  color: $color-default-black;
  outline: none;
  white-space: nowrap;
  border-bottom: size(1px) solid transparent;

  transition: border-color $trans-default;

  user-select: none;

  @include vp-1919 {
    font-size: 18px;
    border-bottom: 1px solid transparent;
  }

  @include vp-1187 {
    font-size: 24px;
    color: $color-default-white;
  }

  @include vp-767 {
    display: inline;
    font-size: 14px;
    line-height: 108%;
    white-space: pre-wrap;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-default-black;

      @include vp-1187 {
        border-color: $color-default-white;
        opacity: .6;
      }
    }

    &:active {
      border-color: $color-default-black;

      @include vp-1187 {
        border-color: $color-default-white;
        opacity: .6;
      }
    }
  }
}

.dropdown--center {
  display: none;
  padding: size(16px) size(35px);

  text-align: center;

  transform: translate3d(-43%, size(16px), 0) rotateX(-30deg);

  @include vp-1919 {
    padding: 16px 37px;

    transform: translate3d(-43%, 16px, 0) rotateX(-30deg);
  }

  @include vp-1187 {
    display: table;

    .main-nav__item {
      min-width: auto;
    }
  }

  .main-nav__item {
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }

  .main-nav__link {
    justify-content: center;
  }
}
